import React from 'react';
import ReactRouter from './router/routes'
import './App.css';
// import FreshChat from 'react-freshchat'

class App extends React.Component{

  render(){
    return (
      <>
        <ReactRouter/>
        {/* <div>
                <FreshChat
                    token="3a37e7ad-88b0-48a3-ad20-828b29202190"
                    email='acumentodayapp@gmail.com'
                    host="https://wchat.freshchat.com"
                    first_name='Atul'
                    onInit={widget => {
                        widget.user.setProperties({
                            email: 'acumentodayapp@gmail.com',
                            first_name: 'Atul',
                            last_name: 'Bengeri',
                        })
                    }}
               />
            </div> */}
        </>
    );
  }
}

export default App;
