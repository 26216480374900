import React, { Suspense } from 'react';
import { Switch, Route, HashRouter } from 'react-router-dom'
import HeaderFourLabelComponent from '../components/uiComponent/headerFourLabel';
const Login = React.lazy(() => import('../screens/loginScreen'));
const Home = React.lazy(() => import('../screens/homeScreen'));
const OfferDetails = React.lazy(() => import('../screens/offerDetailsScreen'));
const Privacy = React.lazy(() => import('../screens/privacyScreen'));
const About = React.lazy(() => import('../screens/aboutScreen'));
const Search = React.lazy(() => import('../screens/searchScreen'));
const Support = React.lazy(() => import('../screens/supportScreen'));
const Test = React.lazy(() => import('../screens/testListScreen'));
const BookDetails = React.lazy(() => import('../screens/bookDetailsScreen'));
const ReadBook = React.lazy(() => import('../screens/readBookScreen'));
const UpdateProfile = React.lazy(() => import('../screens/updateProfileScreen'));
const Register = React.lazy(() => import('../screens/registrationScreen'));
const Verify = React.lazy(() => import('../screens/verifyPayment'))
const Error = React.lazy(() => import('../screens/errorScreen'))

export default class ReactRouter extends React.Component {

    render() {
        return (
            <>
                <HashRouter>
                    <Suspense fallback={<div className="no-data-div">
                        <HeaderFourLabelComponent labelStyle="title-label-style" labelName="Loading ..."/>
                    </div>}>
                        <Switch>
                            <Route exact path='/login' component={Login}/>
                            <Route exact path='/register' component={Register}/>
                            <Route exact path='/' component={Home}/>
                            <Route exact path='/privacy' component={Privacy}/>
                            <Route exact path='/about' component={About}/>
                            <Route exact path='/update' component={UpdateProfile}/>
                            <Route exact path='/support' component={Support}/>
                            <Route exact path='/test/:id' component={Test}/>
                            <Route exact path='/book/:id' component={BookDetails}/>
                            <Route exact path='/offer/:id' component={OfferDetails}/>
                            <Route exact path='/read-book/:flag/:id/:page' component={ReadBook}/>
                            <Route exact path='/search/:flag/:id' component={Search}/>
                            <Route exact path='/verify/:id/:type/:status' component={Verify}/>
                            <Route path="*" component={Error}/>
                        </Switch>
                    </Suspense>
                </HashRouter>
            </>
        )
    }
}